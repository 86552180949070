

const emptyContact = () :Contact => {
    return {
        id: "",
        address_city: "",
        address_extra: "",
        address_postalcode: "",
        address_street: "",
        birthdate: "",
        birthplace: "",
        firstname: "",
        isMember: false,
        taxNumber: "",
        lastname: "",
        birthname: "",
        phone: "",
        memberNumber: "",
        primaryEmail: "",
        salutation: "",
        title: ""
    }
};
const emptyProject = () :Project => {
    return {
        id: "",
        name: "Nicht vorhanden",
        website: "#",
        state: "Unbekannt"
    }
};
const emptyBankAccount = () :Bank_Account => {
    return {
        id: "",
        name: "",
        iban: "",
        debit: true,
        bank:{bic:"",name:""},
        bankAccountTypes: []
    }
};


const EmptyTypesService = {
    emptyContact,
    emptyProject,
    emptyBankAccount,
};

export default EmptyTypesService;