
//previously ApplicationValue in Antrag.tsx
//Class, with which all form data is stored
//ErrorType is only used for emails, so that duplicate email error can be displayed
export default class InputValue {
    value: string | number | boolean | [];
    error: boolean;
    errorType: string;
    constructor(value: string | number | boolean | [], error: boolean) {
        this.value = value;
        this.error = error;
        this.errorType = "";
    }
    getValue() {
        return this.value;
    }
    getError() {
        return this.error;
    }
    getErrorType() {
        return this.errorType;
    }
    setValue(value: string | number | boolean | []) {
        this.value = value;
    }
    setError(error: boolean) {
        this.error = error;
    }
    setErrorType(errorType: string) {
        this.errorType = errorType;
    }
}