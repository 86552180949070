import { useEffect, useState } from "react";
import AuthService from "../services/AuthService";
import { TitleTable } from "../components/TitleTable";
import { Loading } from "../components/Loading";
import { Button, Typography } from "@mui/material";
import DataService from "../services/DataService";
import { useNavigate } from "react-router-dom";
import MemberService from "../services/MemberService";
import EmptyTypesService from "../services/EmptyTypesService";
import EnumToStringService from "../services/EnumToStringService";


interface PersonalDataProps {
    isMobile: boolean
    config: Config
}
declare global {
    type Contact = {
        id: string,
        address_city: string,
        address_extra: string,
        address_postalcode: string,
        address_street: string,
        birthdate: string,
        birthplace: string,
        firstname: string,
        isMember: boolean,
        lastname: string,
        birthname: string,
        taxNumber: string,
        phone: string,
        memberNumber: string,
        primaryEmail: string,
        salutation: string,
        title: string
    }
    type Bank_Account = {
        id: string,
        name: string,
        iban: string,
        debit: boolean,
        bank: {bic:string,name:string},
        bankAccountTypes: []
    }
}


export const PersonalData: React.FC<PersonalDataProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{contact: boolean, bank: boolean}>({contact: false, bank: false});
    const [contactData, setContactData] = useState<Contact>(EmptyTypesService.emptyContact());
    const [bankData, setBankData] = useState<Bank_Account[]>([]);

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performRequest(`/rest/services/beg_PortalContactService/getContact${MemberService.getUrlAddition()}`,finishedLoading,setFinishedLoading,setContactData,"contact", navigate)
            DataService.performRequest(`/bankaccount${MemberService.getUrlAddition()}`,finishedLoading,setFinishedLoading,setBankData,"bank", navigate)
        }
    }, []);

    let bankAccountCounter = 0;

    const buildBankAccountTypeString = (array: []) => {
        let string = "";
        array.map(e => (string+="" + EnumToStringService.getBankAccountType(e) + ", "))
        return string.substring(0,string.length - 2)
    }

    if (!finishedLoading.bank || !finishedLoading.contact) {
        return <Loading />
    }

    return (
        <div className="content">
            <Typography variant="h6">Persönliche Daten</Typography>
            <div className="tableDiv" style={{ width: props.isMobile ? "100%" : "50%", display: "inline-block" }}>
                <TitleTable title="Kontaktdaten" rows={[
                    { name: "Mitgliedsnummer", value: contactData.memberNumber },
                    { name: "Anrede", value: contactData.salutation },
                    { name: "Titel", value: contactData.title },
                    { name: "Vorname", value: contactData.firstname },
                    { name: "Nachname", value: contactData.lastname },
                    { name: "Geburtsname", value: contactData.birthname },
                    { name: "Geburtsdatum", value: new Date(contactData.birthdate).toLocaleDateString() },
                    { name: "Geburtsort", value: contactData.birthplace },
                    { name: "Steuer-ID", value: contactData.taxNumber },
                    { name: "Konfession", value: "Anzeige noch nicht verfügbar" },
                    { name: "Straße", value: contactData.address_street },
                    { name: "Adresszusatz", value: contactData.address_extra },
                    { name: "Postleitzahl", value: contactData.address_postalcode },
                    { name: "Ort", value: contactData.address_city },
                    { name: "Telefon", value: contactData.phone },
                    { name: "E-Mail-Adresse", value: contactData.primaryEmail },
                ]} />
                {/*<Button fullWidth variant="outlined" onClick={() => navigate('/personaldata/edit')}>Bearbeiten</Button>*/}
            </div>
            <div className="tableDiv" style={{ width: props.isMobile ? "100%" : "50%", display: "inline-block", verticalAlign: "top" }}>
                {bankData.map(ba => {
                    bankAccountCounter++;
                    return (<><TitleTable title={`${bankAccountCounter}. Bankverbindung`} rows={[
                        { name: "Kontoinhaber", value: ba.name },
                        { name: "IBAN", value: ba.iban },
                        { name: "BIC", value: ba.bank.bic },
                        { name: "Name der Bank", value: ba.bank.name },
                        { name: "Einzugsermächtigung", value: ba.debit ? "Ja" : "Nein" },
                        { name: "In Verwendung für", value: buildBankAccountTypeString(ba.bankAccountTypes) },
                    ]} />
                    <Button fullWidth variant="outlined" onClick={() => navigate(`/bankaccount/edit/${ba.id}`)}>Bearbeiten</Button></>)
                })}
                <Button fullWidth variant="outlined" style={{marginTop: bankData.length > 0 ? "10px" : "0px"}} onClick={() => navigate(`/bankaccount/create`)}>Neues Konto Hinzufügen</Button>
            </div>
        </div>
    )
}

