import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import DataService from '../../../services/DataService';
import { ProjectDto, TaskDto } from '../../../types';
import LoanService from '../../../services/LoanService';

interface CheckChangesTaskProps {
  isMobile: boolean;
  task: TaskDto;
}

declare global {}

export const SignLoanContractTask: React.FC<CheckChangesTaskProps> = (props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [project, setProject] = useState<ProjectDto | null>(null);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [riskInfoChecked, setRiskInfoChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const navigate = useNavigate();
    //Set default values so undefined error is prevented, there is probably a better solution to do this
    const [config, setConfig] = useState<Config>({
      cooperativeName: "Bürgerenergie",
      nominalShareValue: 1,
      maxAllowedHeldShares: 1,
      loanMinSum: 1000,
      sharePurchaseFeeRate: 1,
      bank_owner: "",
      bank_iban: "",
      bank_name: "",
      bank_bic: "",
      creditorIdentificationNumber: "",
      statuteUrl: "",
      email: "",
      address_street: "",
      address_extra: "",
      address_postalcode: "",
      address_city: "",
      address_country: "",
      dataProtectionRegulationUrl: "",
      legalNoticeUrl: ""
    });

  useEffect(() => {

    if (!AuthService.isLoggedIn()) {
      AuthService.doLogin();
    }

        //Request to fetch configurable data from backend service (backend jmix portal)
        DataService.getConfig(setConfig);
  }, []);

  useEffect(() => {
    const loanId = props.task.variables.loanId as string;
    if(loanId){
      LoanService.getProjectForLoan(loanId).then((project: ProjectDto) => {
        setProject(project);
      }).catch((error) => {
        console.error("Error loading project:", error);
      });
    }
  }, [props.task])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.files ? event.target.files[0] : null);
  }

  return (
    <div className='content'>
      <Typography variant='h5'>Darlehensvertrag unterschreiben und hochladen</Typography>
      <br></br>
      <Typography>Bitte lade den generierten Darlehensvertrag herunter und unterschreibe ihn entsprechend.</Typography>
      <Button variant="contained" onClick={() => {DataService.downloadAttachment(props.task.variables['loanContractFileRef'], props.task.variables['loanContractFileName']);}
        }>{props.task.variables['loanContractFileName']} Herunterladen</Button>
        <br></br>
      <FormControlLabel
        control={<Checkbox checked={privacyChecked} onChange={(e) => setPrivacyChecked(e.target.checked)} />}
        label={
          <Typography>
          Ich habe die <a href={config.dataProtectionRegulationUrl}>Datenschutzerklärung</a> gelesen und akzeptiere sie.
        </Typography>
        }
      />
      <br/>
      {project?.riskInformationDocumentFileRef ? <FormControlLabel
        control={<Checkbox checked={riskInfoChecked} onChange={(e) => setRiskInfoChecked(e.target.checked)} />}
        label={
          <Typography>
            Ich habe die <a href="#" onClick={() => DataService.downloadAttachment(project.riskInformationDocumentFileRef , project.riskInformationDocumentFileRef.split("?name=")[1].replace(".pdf",""))}>Risikoinformationen</a> gelesen und akzeptiere sie.
          </Typography>
        }
      /> : ''}
      <br/>
      {project?.riskInformationDocumentFileRef ?<FormControlLabel
        control={<Checkbox checked={termsChecked} onChange={(e) => setTermsChecked(e.target.checked)} />}
        label={
          <Typography>
            Ich habe die <a href="#" onClick={() => DataService.downloadAttachment(project?.loanConditionsDocumentFileRef , project.loanConditionsDocumentFileRef.split("?name=")[1].replace(".pdf",""))}>Zeichnungsbedingungen</a> gelesen und akzeptiere sie.
          </Typography>
        }
      />: ''}
        <Typography>Wenn du den Vertrag unterschrieben hast lade ihn hier hoch und klicke auf den Button "VERTRAG ABSCHICKEN":</Typography>
        <Stack spacing={2} direction="row">
         <Button
          variant="contained"
          component="label"
          color={selectedFile ? 'secondary' : 'primary'}
        >
          {selectedFile ? selectedFile.name : "Unterschriebenen Vertrag hochladen"}
          <input
            type="file"
            hidden
            onChange={handleFileChange}
          />
          
      </Button>

      <Button  disabled={!selectedFile || !privacyChecked || !riskInfoChecked || !termsChecked}  variant="contained" color="primary" onClick={async () => {
        await DataService.uploadLoanContract(selectedFile, props.task.id);
        navigate("/projectloans");
        }}>
            Vertrag abschicken
          </Button>
          </Stack>
    </div>
  );
};
