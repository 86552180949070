import { FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import AuthService from '../services/AuthService';
import InputValue from '../classes/InputValue';
import DataService from '../services/DataService';
import MemberService from '../services/MemberService';
import EmptyTypesService from '../services/EmptyTypesService';
import { LoadingButton } from '@mui/lab';
import { validationService } from '../services/ValidationService';

interface NewLoanProps {
    isMobile: boolean
    config: Config
}

declare global {
    type PaymentOption = {
        id: string,
        code: string,
        name: string,
        activated: boolean
    }
}

export const NewLoan: React.FC<NewLoanProps> = (props) => {

    const navigate = useNavigate();
    const { id } = useParams();

    const [finishedLoading, setFinishedLoading] = useState<{ contact: boolean, project: boolean, paymentOptions: boolean }>({ contact: false, project: false, paymentOptions: false });
    const [contactData, setContactData] = useState<Contact>(EmptyTypesService.emptyContact());
    const [projectData, setProjectData] = useState<Project[]>([EmptyTypesService.emptyProject()]);
    //Saves list of payment options from request
    const [paymentOptions, setPaymentOptions] = useState<PaymentOption[]>([]);
    //Var if button should show "Loading"
    const [btnIsLoading, setBtnIsLoading] = useState<boolean>(false)
    //a useState var needs to change in order to reload the screen, so you can do that with reload(!reloadVar);
    const [reloadVar, reload] = useState<boolean>(false)

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performRequest(`/rest/services/beg_PortalContactService/getContact${MemberService.getUrlAddition()}`, finishedLoading, setFinishedLoading, setContactData, "contact", navigate);
            DataService.performRequest(`/projects?id=${id}`, finishedLoading, setFinishedLoading, setProjectData, "project", navigate);
            DataService.performAnonymousRequest(`/rest/entities/beg_PaymentOption`, finishedLoading, setFinishedLoading, setPaymentOptions, "paymentOptions", navigate);
        }
    }, []);

    const [values] = useState<any>({
        loanSum: new InputValue(props.config.loanMinSum, false),
        paymentType: new InputValue("", false),
    });
    const style = {
        marginTop: "10px",
    }

    const sendRequest = async () => {
        await DataService.performAsyncPostRequest(`/loan/application`, navigate, {
            contact: { id: contactData.id },
            loanSum: values.loanSum.getValue(),
            paymentType: getPaymentTypeFromOption(values.paymentType.getValue()),
            project: { id: projectData[0].id }
        })
    }

    //temporary function until different approches to paymentoption are unified
    const getPaymentTypeFromOption = (value: string) => {
        if (value === "sepa") return "DEBT_ORDER"
        else if (value === "seperate") return "BANK_TRANSFER"
        else return value;
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueBefore = values["loanSum"].getValue();
        values["loanSum"].setValue(e.target.value)
        const newValue = parseInt(e.target.value);
        if (!validationService.validateFunction(values, "loanSum", "positiveFloat", false, false, props.config)) {
            values["loanSum"].setValue(valueBefore);
        }
        if(newValue % 1000 !== 0) {
            values["loanSum"].setError("Darlehensbetrag muss in 1000er Schritten angegeben werden!");
        }

        reload(!reloadVar);
    }

    return (
        <div className='content'>
            <div className="tableDiv" style={{ width: props.isMobile ? "100%" : "50%", display: "inline-block" }}>
                {/*<Typography variant='body1'>Der Darlehensantragsprozess wird in Kürze digitalisiert. Bis dahin können Sie sich gerne direkt an uns wenden. Ignorieren Sie bitte Folgendes.</Typography>
                <Typography variant='caption'>{id}</Typography>*/}
                <Typography variant='h6'>Zeichnungsabsicht</Typography>
                <Typography variant='body2'>Für Projekt {projectData[0].name}</Typography>
                <Typography variant='subtitle1'>Persönliche Angaben</Typography>

                <FormControl style={style} fullWidth>
                    <InputLabel size='small'>Anrede *</InputLabel>
                    <Select
                        value={contactData.salutation}
                        label="Anrede"
                        name='salutation'
                        variant="outlined"
                        size="small"
                        disabled
                    >
                        <MenuItem value={"Herr"}>Herr</MenuItem>
                        <MenuItem value={"Frau"}>Frau</MenuItem>
                        <MenuItem value={"Keine Anrede"}>Keine Anrede</MenuItem>
                    </Select>
                </FormControl>
                <TextField style={style} fullWidth value={contactData.title} name='title' label="Titel" variant="outlined" size="small" disabled />
                <TextField style={style} fullWidth value={contactData.firstname} name='firstname' label="Vorname" variant="outlined" size="small" required disabled />
                <TextField style={style} fullWidth value={contactData.lastname} name='lastname' label="Nachname" variant="outlined" size="small" required disabled />
                <TextField style={style} fullWidth value={contactData.address_street} name='addressStreet' label="Straße, Hausnummer" variant="outlined" size="small" required disabled />
                <TextField style={style} fullWidth value={contactData.address_extra} name='addressExtra' label="Addresszusatz (optional)" variant="outlined" size="small" disabled />
                <TextField style={style} fullWidth value={contactData.address_postalcode} name='addressPostalcode' label="Postleitzahl" variant="outlined" size="small" required disabled />
                <TextField style={style} fullWidth value={contactData.address_city} name='addressCity' label="Ort" variant="outlined" size="small" required disabled />
                <TextField style={style} fullWidth value={contactData.primaryEmail} name='email' type='email' label="E-Mail" variant="outlined" size="small" required disabled />
                <Typography variant='subtitle1' style={style}>Darlehen</Typography>
                <TextField type='number' fullWidth style={style} value={values.loanSum.getValue()} onChange={handleChange} error={values.loanSum.getError()} name='loanSum' label="Betrag des Darlehens" variant="outlined" size="small" required  InputProps={{
                    inputProps: { 
                        min: props.config.loanMinSum, 
                        step: 1000
                    }
                }}/>
               {values.loanSum.getError() ? (<FormHelperText error={true}>
            Der Darlehensbetrag darf nur in 1000er Schritten gewählt werden!
          </FormHelperText>) : ''}
                <FormControl style={style} fullWidth>
                    <FormLabel>Zahlungsoption *</FormLabel >
                    <RadioGroup
                        value={values.paymentType.getValue()}
                        onChange={e => { values.paymentType.setValue(e.target.value); reload(!reloadVar) }}
                        name='paymentOption'
                    >
                        {paymentOptions.map(e => e.activated && <FormControlLabel key={e.id} value={e.code} control={<Radio />} label={e.name} />)}
                    </RadioGroup>
                </FormControl>
                <div style={{ textAlign: "right" }}>
                    <LoadingButton loading={btnIsLoading} variant="contained" disableElevation sx={{ marginTop: "10px" }} onClick={async () => {
                        setBtnIsLoading(true);
                        let isError = false;
                        if (!validationService.validateFunction(values, "loanSum", "positiveFloat", false, false, props.config)) isError = true;
                        if (values.paymentType.getValue() === "") {
                            isError = true;
                        }
                        if (isError) {
                            reload(!reloadVar);
                            setBtnIsLoading(false);
                            return;
                        }
                        await sendRequest();
                        setBtnIsLoading(false);
                        navigate("/projectloans")
                    }}>Zeichnungsabsicht abschicken</LoadingButton>
                </div>
            </div>
        </div>
    )

}
