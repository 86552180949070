
let memberData: Member[] = [];

let currentMember: Member = {id: "",name: "",memberNumber: ""};

const setMemberData = (data: Member[]) => {
    if (currentMember.id === "") {
        setCurrentMember(data[0])
    }
    memberData = data;
}

const getMemberData = () => {
    return memberData;
}

const setCurrentMember = (data: Member) => {
    currentMember = data;
}

const getCurrentMember = (): Member  => {
    return currentMember;
}

const getUrlAddition = () => {
    if (currentMember.id === "") {
        return "";
    } else {
        return `?memberId=${currentMember.id}`
    }
}

const MemberService = {
    setMemberData,
    getMemberData,
    setCurrentMember,
    getCurrentMember,
    getUrlAddition
};

export default MemberService;