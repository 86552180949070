import { Typography } from "@mui/material"
import { useParams } from "react-router-dom"


export const Error: React.FC = () => {
    let {status} = useParams();
    return (
        <div className="content">
            <Typography variant="h6">Mitgliederportal</Typography>
            <Typography variant="body1">Fehler {status ? status: "404"}</Typography>
        </div>
    )
}
